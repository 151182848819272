/*----Card Carousel -----*/

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-scrollbar{
  display: none;
}
