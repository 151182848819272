/*--Header Top--*/
button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.header-top-wrapper {
  background-color: var(--bs-ghost-white);
  .header-top {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--Navbar--*/

.navigation-bar {
  background-color: #fff;
  z-index: 9999;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    .logo {
      height: 62px;
      .logo-img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .navbar-btn {
      display: none;
      color: #002e71;
      font-size: 30px;
    }
    .navbar-content {
      display: flex;
      flex: 1;
      height: 100%;
      z-index: 9999;
      .close-btn {
        display: none;
        font-size: 25px;
        color: #002e71;
      }
      .menu {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .menu-item {
          font-size: 15px;
          color: #023d84;
          border-bottom: 3px solid transparent;
          padding-bottom: 2px;
          font-weight: var(--fw-bold);
          &:focus {
            border-bottom: 3px solid #3a7de16e;
            border-radius: 0 0 3px 3px;
          }
        }
      }
      .btn-group {
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        .login-btn {
          width: 102px;
          height: 42px;
          padding: 0 20px;
          background: #002e71;
          color: white;
          font-size: 18px;
          border-radius: 4px;
          text-transform: uppercase;
          font-weight: var(--fw-bolder);
          outline: none;
          border: 0;
        }
        .istanbul-btn {
          width: 240px;
          height: 42px;
          padding: 0 15px;
          background: #c4005c;
          color: white;
          font-size: 18px;
          border-radius: 4px;
          font-size: 15px;
          margin-left: 12px;
          font-weight: var(--fw-bolder);
          outline: none;
          border: 0;
        }
        .login-btn {
          width: 165px;
          height: 42px;
          padding: 0 0px;
          color: white;
          font-size: 18px;
          border-radius: 4px;
          font-size: 15px;
          margin-left: 12px;
          font-weight: var(--fw-bolder);
          outline: none;
          border: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .navigation-bar .navbar {
    .navbar-content {
      .menu {
        width: 55%;
      }
      .btn-group {
        width: 45%;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .navigation-bar {
    .navbar {
      .navbar-btn {
        display: block;
      }
      .navbar-content {
        transform: translateX(-100%);
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        flex-direction: column;
        background: #f9f9f9;
        transition: 250ms;
        .close-btn {
          display: block;
          position: absolute;
          top: 5%;
          right: 5%;
          z-index: 10;
          border: none;
          background: none;
          font-weight: bold;
        }
        &.show {
          transform: translateX(0);
          visibility: visible;
        }
        .menu {
          width: 100%;
          height: 50%;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 50px;
          .menu-item {
            color: #002e71;
            margin-bottom: 25px;
            font-size: 18px;
            font-family: Montserrat;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .btn-group {
          width: 100%;
          height: 50%;
          flex-direction: column-reverse;
          justify-content: start;
          align-items: center;
          .login-btn {
            width: 240px;
          }
          .istanbul-btn {
            margin-left: 0;
            margin-top: 25px;
          }
          .login-btn {
            margin-left: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .header-top-wrapper .header-top {
    justify-content: center;
  }
}
