/*-----Filter Accordion-----*/
.accordion {
  margin-bottom: 15px;
  border-radius: 2px;
  &:last-child {
    margin-bottom: 0;
  }
}
.accordion-item {
  border: 2px solid #E6EBF2;
  &:first-of-type{
    border-radius:0;
  }
  &:last-of-type{
    border-radius:0;
  }
}
.accordion-button {
  height: 2.625rem;
  font-size: 14px;
  padding: 8px;
  font-weight: var(--fw-bold);
  background-color: #FFFFFF;
  border-bottom: 1px solid #eee;
  &:not(.collapsed){
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }
  &.collapsed{
    border-bottom: 0;
    color: #0063b0;
    background-color: #fff;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    background: white;
    color: #0063b0;
    box-shadow: inset 0 0px 0 #ddd;
  }
  &:after {
   
  }
}
.accordion-body {
  padding: 10px;
}
@media (max-width: 768px) {
  .accordion-body {
    padding: 20px;
  }
}
