.message_page{
  width: 100%;
  height: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.message_page .jumbotron{
  text-align: center;
  flex-basis: 30em;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.message_page .jumbotron h1{
  font-size: calc(1.5rem + 2vmin);
  word-break: break-word;
}

.message_page .jumbotron .lead{
  font-size: calc(.9rem + 1vmin);
}