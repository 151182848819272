
@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Bold';
	src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Black';
	src: url('./assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-ExtraBold';
	src: url('./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Medium';
	src: url('./assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'OpenSans-Medium';
	src: url('./assets/fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

/*FONT FAMILY*/
.fw-bold {
	font-family: 'Montserrat-Bold', sans-serif;
}
.fw-extra-bold {
	font-family: 'Montserrat-ExtraBold', sans-serif;
}
.fw-regular {
	font-family: 'Montserrat-Regular', sans-serif;
}
.fw-black {
	font-family: 'Montserrat-Black', sans-serif;
}
.fw-medium {
	font-family: 'Montserrat-Medium', sans-serif;
}
.fw-open-medium {
	font-family: 'OpenSans-Medium', sans-serif;
}
/*FONT SİZE*/
.fs-sm {
	font-size: clamp(12px, 14px, 16px);  /*12px - 16px*/
}
.fs-md {
	font-size: clamp(15px, 18px, 20px);  /*15px - 20px*/
}
.fs-lg {
	font-size: clamp(40px, 70px, 85px); /*40px-85px*/
}


@-webkit-keyframes loading-spinner {
	0% {
		-webkit-transform: rotate(0deg);
   }
	100% {
		-webkit-transform: rotate(360deg);
   }
}
@keyframes loading-spinner {
	0% {
		transform: rotate(0deg);
   }
	100% {
		transform: rotate(360deg);
   }
}
:root {
	--bs-primary: #1e5098;
	--bs-primary-rgb: 0, 83, 154;
	--bs-ghost-white: #f9f9f9;
	--bs-ghost-white-rgb: 249, 249, 249;
	--bs-dark-sky: #023d84;
	--bs-dark-sky-rgb: 2, 61, 132;
	--bs-dark-liver: #4e4e4e;
	--bs-dark-silver: #858585;
}
.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: var(--bs-primary) !important;
	--bs-btn-border-color: var(--bs-primary);
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #0b5ed7;
	--bs-btn-hover-border-color: #0a58ca;
	--bs-btn-focus-shadow-rgb: 49, 132, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #0a58ca;
	--bs-btn-active-border-color: #0a53be;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--bs-primary);
	--bs-btn-disabled-border-color: var(--bs-primary);
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a:link{
	text-decoration: none;
}

#root {
	margin: 0;
	padding: 0;
	height: 100%;
	
	
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	margin: 0;
	font-family: 'Montserrat-Regular', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

html {
	margin: 0;
	padding: 0;
	height: 100%;
}

/*BACKGROUND COLOR*/
.bg-ghost-white {
	background-color: var(--bs-ghost-white);
}
.bg-dark-sky {
	background-color: var(--bs-dark-sky);
}

/*TEXT COLOR*/
.text-catalina-blue {
	--bs-text-opacity: 1;
	color: #002e71;
}
.text-dark-liver {
	--bs-text-opacity: 1;
	color: var(--bs-dark-liver);
}
.text-dark-silver {
	--bs-text-opacity: 1;
	color: var(--bs-dark-silver);
}
.text-primary-blue {
	--bs-text-opacity: 1;
	color: #1e5098;
}

.loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
}
.loading-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0.8;
	top: 0;
	left: 0;
	z-index: 11;
}
.loading-spinner {
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db;
	width: 35px;
	height: 35px;
	-webkit-animation: spin 1s linear infinite;
	animation: loading-spinner 1s linear infinite;
	z-index: 12;
}
.loading-text {
	z-index: 12;
}
.hide {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}
.show {
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}
.sr-only {
	display: none;
}


@media (min-width:1600px) {
	.container{
		max-width: 1440px;
	}
}
