.nav-tabs{
    border: 0;
    border-bottom: 1px solid#707070;
    font-size:17px;
    --bs-nav-tabs-border-width:0;
    --bs-nav-link-padding-y:15px;
    --bs-nav-tabs-link-active-color:#16467F;
    --bs-nav-link-font-weight:var(--fw-extra-bold);
    --bs-nav-link-color:#A0BFE2;
    --bs-nav-link-hover-color:#16467F;
    .nav-link{
        font-weight: var(--fw-extra-bold);
        --bs-nav-tabs-link-active-bg: transparent;
    }
}
.tab-content{
    margin-top: 40px;
}

body{
    background-color:#F9F9F9;
}

.tab-item-header{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .tab-header-title{
        font-size:28px;
        font-weight: var(--fw-extra-bold);
        color:#1D428A;
        margin-bottom: 0;
    }
    .tab-header-info{
        font-size:18px;
        color:#000;
        font-weight: var(--fw-bold);
        margin-left: 25px;
    }
}

.tab-footer{
    
    
}

.tab-footer{
    font-size:clamp(1rem,1.2rem,1.5rem); 
    font-weight: var(--fw-bold);
    color:#1E5098;
    &-result{
        width:100%;
        &-title{
            flex:3;
            display: flex;
            justify-content: flex-end;
        }
    }
    &-value{
        display: flex;
        justify-content: flex-end;
        height:100%;
        &.error{
            color: #D9121F;
        }
    }
    .error-message{
        margin-top: 5px;
        font-size:clamp(1.2rem,1.5rem,2rem); 
        font-weight: var(--fw-bold);
        color: #D9121F;
    }
    &-btn{
        padding: 1rem 2.5rem;
        border: 1px solid #1E5098;
        color:#1E5098;
        background-color: white;
        font-size:clamp(1rem,1.2rem,1.5rem); 
        font-weight: var(--fw-bold);
        border-radius: 16px;
        margin-top: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &.disabled{
            background: #D1D0D0;
            color:#858585;
            border:1px solid #858585;
        }
    }
}
