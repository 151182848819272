$background-color_1: var(--bs-white);
$background-color_2: var(--bs-primary);

.backImage {
	// background-image: url('../../assets/images/home/Group147061.png');
	// background-size: cover;
	// height: auto;
	// background-repeat: no-repeat;
    background-color: #F9F9F9;
}
.search-icon {
	display: inline-block;
	width: 26px;
	height: 26px;
	background-image: url('../../assets/images/standart/magnifier.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.poll-container {
	background-image: url('../../assets/images/home/oylamayakatil.png');
	background-size: cover;
	min-height: 35vh;
	background-repeat: no-repeat;
}
i.play-btn {
	background-image: url('../../assets/images/home/Video.svg');
	width: 140px;
	height: 140px;
}
.uyg-btn {
	&:before {
		content: '';
		position: absolute;
		width: 50%;
		height: 5vh;
		background-color: $background-color_1;
		border: 1px solid var(--bs-primary);
		z-index: -1;
	}
}
.ist-senin {
	height: 75vh;
}
.main-content {
	.slider-cart {
		box-shadow: 0 1rem 3rem #21343014 !important;
	}
	.swiper-button-next {
		&:after {
			content: '';
			background-image: url(../../assets/images/standart/arrow-small-left.svg);
			background-position: center;
			background-repeat: no-repeat;
			width: 15px;
			height: 13px;
		}
		top: var(--swiper-navigation-top-offset,95%);
		width: 35px;
		height: 35px;
		border: 1px solid #ddd;
		margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
		background-color: $background-color_2;
		right: 0;
	}
	.swiper-button-prev {
		&:after {
			content: '';
			background-image: url(../../assets/images/standart/arrow-small-right.svg);
			background-position: center;
			background-repeat: no-repeat;
			width: 15px;
			height: 13px;
		}
		top: var(--swiper-navigation-top-offset,95%);
		width: 35px;
		height: 35px;
		border: 1px solid #ddd;
		margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
		background-color: $background-color_2;
		left: 40%;
		right: 0;
		left: 50vh;
	}
	.swiper-rtl {
		.swiper-button-next {
			left: 40%;
			right: 0;
			left: 50vh;
		}
		.swiper-button-prev {
			right: 0;
		}
	}
}
.container-img {
	object-fit: contain;
	width: 100%;
}
.video-cart {
	margin-top: 0;
}
@media (max-width:1400) {
	.video-cart {
		margin-top: 2rem !important;
	}
}
@media (max-width:1201px) {
	.video-cart {
		position: relative !important;
		margin-top: 3rem;
	}
	.main-content {
		.swiper-button-prev {
			left: 40vh;
		}
		.swiper-rtl {
			.swiper-button-next {
				left: 40vh;
			}
		}
	}
}


/*Home Haberler*/
.news-section-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-big-title{
        font-size:50px;
        font-family: var(--fw-extra-bold);
        color: #002E71;
    }
    &-small-title{
        font-size:16px;
        font-family: var(--fw-bold);
        color:#1D428A;
    }
}
.news-section-hr{
    height: 3px;
    background-color: #A0BFE2;
}

/**/
.news-section-card{
    display: flex;
    margin-bottom: 15px;
    &:last-child{
        margin-bottom: 0;
    }
    &-logo{
        width:200px;
        height:158px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-img{
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            filter:grayscale(100%);
        }
    }
    &-content{
        flex:1;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-date{
            display: flex;
            align-items: center;
            color: #1565C0;
            font-size: 16px;
            font-weight: var(--fw-bold);
            margin-bottom: 5px;
            span{
                margin-left: 10px;
            }
        }
        &-title{
            height: 47px;
            display: flex;
            align-items: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -ms-box-orient: vertical;
            box-orient: vertical;
            -webkit-line-clamp: 5;
            -moz-line-clamp: 5;
            -ms-line-clamp: 5;
            line-clamp: 5;
            overflow: hidden;
            margin-bottom: 7px;
            h4{
                color: #1B1B1B;
                font-size: 18px;
                font-weight: var(--fw-bold);
            }   
        }
        &-text{
            height: 75px;
            display: flex;
            align-items: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -ms-box-orient: vertical;
            box-orient: vertical;
            -webkit-line-clamp: 5;
            -moz-line-clamp: 5;
            -ms-line-clamp: 5;
            line-clamp: 5;
            overflow: hidden;
            margin-bottom: 10px;
            p{
                color: #223040;
                font-size: 16px;
                font-weight: var(--fw-regular);
            }  
        }
    }
}
//swiper style
.news .swiper {
    width: 100%;
    height: 100%;
  }
  
.news .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.news .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


