/*-----Filter-----*/
.filter-clear-btn {
  width: 100%;
  height: 40px;
  background-color: #1565C0;
  color: white;
  border-radius: 2px;
  font-size: 16px;
  font-weight: var(--fw-medium);
  outline: none;
  border:none;
  &.disabled {
    background-color: #a0bfe2;
    pointer-events: none;
  }
}

/*Filter Accordion Item*/
.form-check-input:checked{
  background: #0d3e76;
  color: #1565C0;
  border: none;
  box-shadow: inset 0 0px 0 #ddd;
}
.form-check-input:focus{
  box-shadow: inset 0 0px 0 #ddd;

}
.form-check-input{
  border:1px solid #CCCCCC;
  transform: translateY(-2px);
}

.form-check .form-check-label{
  font-size:14px;
}
