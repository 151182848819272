.my-account-card{
    border-top: 1px solid #E7E7E7;
    &:last-child{
        border-bottom: 1px solid #E7E7E7;

    }
    &-photo{
        width:100%;
        height:100%;
        border-radius: 8px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &-content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-title{
            font-size:36px ;
            font-weight: var(--fw-bolder);
            color:#1D428A;
        }
        &-subtitle{
            font-size: 18px;
            font-weight:var(--fw-medium);
            color:#9B9B9B;
        }
        &-point{
            color: #1E5098;
            font-size:18px;
            font-weight: var(--fw-medium);
            .big-point{
                font-weight: var(--fw-extra-bold);
                font-size:35px;
            }
        }
    }
    &-action{
        display: flex;
        align-items: center;
        height: 100%;
        .form-check{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            &-input{
                width:1.5rem;
                height: 1.5rem;
            }
        }
        .delete-btn{
            width:1.7rem;
            height: 1.7rem;
            img{
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}
