/*-----SearchBar-----*/
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  border: 1px solid #A0BFE2;
  border-radius: 4px;
  padding: 0.625rem 1.25rem 0.625rem 0.938rem;
  font-size: 18px;
  margin-bottom: 15px;
  &-input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
  }
}
