/*-----Pagination-----*/
.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-single-link {
      font-size: 17px;
      color: #1565c0;
    }
    &-numbers {
      margin: 0 20px;
      display: flex;
      gap: 15px;
      &-item {
        width: 30px;
        height: 30px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
        background-color: #fff;
        color: '#2F79ED';
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 8px #b7bdc480;
        &.active {
          color: #fff;
          background-color: #1565c0;
        }
      }
    }
  }