.side-bar {
    background-image: url(../../assets/images/projects/background-sidebar.png);
    background-repeat: no-repeat;
}



.total_score {
    border: 3px solid #1565C0;
    padding: 2rem 2.5rem ;
    
}

.profile {
    margin-top: 5em;

}

.btn {
    padding-left: 0 2em 0 2em;
    border-color: var(--bs-btn-active-border-color);
}
p {
    font-size: 13px;
}

.card_img {
    max-width: 100%;

}

.shaping {
        position: absolute;
        right: 0;
        width: 60%;
		border-radius: 0 0 0 25px;
		display: flex;
		padding: 0.4em;
		font-weight: 600;
		color: white;
}

.shaping2 {
    position: absolute;
    right: 0;
    // width: 60%;
    border-radius: 0 0 0 25px;
    display: flex;
    padding: 0.4em;
    font-weight: 600;
    color: white;
}

.custom-button {
	height: 66px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 16px;
	border: none;
	font-weight: 600;
	font-size: 14px;

	&.secondary-button {
		background: #0077F7;
		color: white;
	}

	&.secondary-soft-button {
		background: #C2DEFD;
		color: #0077F7;
	}

	&.primary-button {
		background: #1565C0;
		color: white;
	}

	&.cancel-button {
		background-color: #FB236A;
		color: white;
	}
}

.proje-tanim-title {
    color: #1E5098; 
    font-weight: bold;
    font-size: 40px;
}

.button-project {
    margin: 20em;
}

.input-btn {
  border: 2px solid #16467F;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  display: block;
  cursor: pointer;
  line-height: 1.5;
  text-align: center;
  padding: 30px !important;
  margin-bottom: 20px;
  border-style: dashed;
}

.desc {
    color: #BF193A;
    font-size: 14px;
}

.tema_img {
    width: 4em;
}   

//tabs 
.nav-tabs {
    border-bottom: 1px solid #183a61;
}

.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
    background-color: #1565C0;
    color: white;
}

.nav-tabs .nav-link {
    border: 0.5px gray solid ;  
    border-radius: 10px 10px 0 0;
    color: #1565C0;
    
}

.nav-justified > .nav-link, 
.nav-justified .nav-item {
    margin-right: 25px;
}

.back-btn{
    padding:10px 25px;
    background-color: #1565C0;
    color:#fff;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    &:hover{
        color:#fff;
    }
}
.fav-btn{
    padding:8px 20px;
    background-color: #fff;
    border: 1px solid #1565C0;
    color:#1565C0;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    transition: 250ms;
    cursor: pointer;
    &:hover{
        transform: translateY(-5px);
    }
}
.form-control { 
    -webkit-appearance: menulist !important; 
}

.text-add {
    color: #0777F7; 
    font-size: 16px; 
    font-weight: 600;
}