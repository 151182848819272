

.baskan_img {
    width: 70%;
    height: 100%;
    object-fit: cover;
    position: sticky;
    
}

.content {
    padding-top: 5em;
    font-size: 14px;
}

.kurumsal_imza {
    width: 45%;
}