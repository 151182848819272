.card-container {
	border-radius: 5em;
}


.project-card {
    height: auto;
	
}

.card-content {
	background-color: white;
	border-radius: 0 0 1em 1em ;
	
}

.shape {
    position: absolute;
		top: 0;
		right: 0;
		border-radius: 10px 0 0 10px;
		display: flex;
		align-items: center;
		padding: 0.7em;
		font-weight: 600;
		color: white;
}
.number-shape {
    position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		width:40px;
		height:40px;
		font-weight: 600;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
}


.btn {
    position: relative;
    border-radius: 5px;
	.txt{
		float: left;
		
	}
}

.favorite_img,
.search_img {
    float: right;
}


    